import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const DerEinzelneUndDieGesellschaft = ({ data }) => (
    <InnerPage title="Der Einzelne und die Gesellschaft" description="">
        <IntroHolder
            title="Der Einzelne und die Gesellschaft"
            subtitle="Eine wechselseitige Beziehung auf dem Weg zur Weltkultur"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Woran Bahá’í glauben',
                'current-item': 'Der Einzelne und die Gesellschaft',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/zitate">
                Die Vereinigung der ganzen Menschheit ist das Kennzeichen der
                Stufe, der sich die menschliche Gesellschaft heute nähert. Die
                Einheit der Familie, des Stammes, des Stadtstaates und der
                Nation ist nacheinander in Angriff genommen und völlig erreicht
                worden. Welteinheit ist das Ziel, dem eine gequälte Menschheit
                zustrebt.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <div id="content">
                <p>
                    Der Mensch und die Beziehung zu seinen Mitmenschen standen
                    immer im Mittelpunkt aller Religionen. Ohne Nächstenliebe
                    ist auch heute gesellschaftlicher Zusammenhalt nicht
                    denkbar.
                </p>
                <div>
                    <p>
                        Ohne Nächstenliebe ist auch heute gesellschaftlicher
                        Zusammenhalt nicht denkbar. Die{' '}
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/">
                            Botschaft Bahá’u’lláhs
                        </Link>{' '}
                        weist den heutigen Menschen aber darauf hin, dass er in
                        der komplexer werdenden Welt seinen Blick auf das
                        Wohlergehen der ganzen Menschheit richten sollte.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={
                                data.einzelnerUndDieGesellschaft.childImageSharp
                                    .fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                </div>
                <div>
                    <p>
                        Die großen Herausforderungen und Krisen, denen die
                        Menschheit heute dabei begegnet, können auch als Zeichen
                        des Übergangs von der Entwicklungsstufe der Kindheit in
                        ein beginnendes Zeitalter der Reife der Menschheit
                        gesehen werden.
                    </p>
                </div>
                <div>
                    <p>
                        Für den Zusammenhalt in einer zunehmend globalisierten
                        Welt muss auch das Verhältnis des{' '}
                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/der-einzelne/">
                            Einzelnen
                        </Link>{' '}
                        zu seinem Umfeld und zu den verschiedenen
                        gesellschaftlichen Institutionen neu gedacht werden. In
                        der Verantwortung hierfür und mit dem Blick auf das
                        Wohlergehen der ganzen Menschheit öffnet sich ein Weg
                        zur Überwindung der heute überall wahrgenommenen
                        Ohnmacht.
                    </p>
                </div>
                <CenteredQuote referenceNumber="2">
                    Es rühme sich nicht, wer sein Vaterland liebt, sondern wer
                    die ganze Welt liebt.
                </CenteredQuote>
                <Reference>
                    <p>
                        1. Shoghi Effendi, Die Weltordnung Bahá’u’lláhs, S. 295
                    </p>
                    <p>2. Bahá’u’lláh, Botschaften aus Akka 7:13</p>
                </Reference>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DerEinzelneUndDieGesellschaft;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: {
                eq: "der-einzelne-und-die-gesellschaft-feature.jpg"
            }
        ) {
            ...fluidImage
        }
        einzelnerUndDieGesellschaft: file(
            relativePath: { eq: "EinzelnerundGesellschaft.m.png" }
        ) {
            ...fluidImage
        }
    }
`;
